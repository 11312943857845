import React, { useEffect } from "react";
import Features from "../components/home/Features";
import Header from "../components/home/Header";
import Merchant from "../components/home/Merchant";
import CompanyOverview from "../components/home/CompanyOverview";
import StepByStep from "../components/home/StepByStep";

import ReactGA from "react-ga4";
const HomePage = () => {
  ReactGA.initialize("G-4W87Q53T7Y");
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: "/", title: "Veiwing Home Page" });

  useEffect(() => {
    const registerVisit = async () => {
      await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          const ipAddress = data.ip;
          const query = window.location.search;
          const params = new URLSearchParams(query);
          const source = params.get("s");

          const formData = new FormData();
          formData.append("source_website", document.referrer);
          formData.append("page", window.location.href);
          formData.append("ip", ipAddress);
          if (source !== null) {
            formData.append("reference", source);
          }

          fetch("https://py.dws.teqneia.com/create-report/?tenant_id=1", {
            method: "POST",
            body: formData,
          }).then((response) => {
            if (!response.ok) {
              console.error("Unable to register visit.");
            }
            return response.json();
          });

          params.delete("source");
          const newUrl = window.location.pathname;
          // eslint-disable-next-line no-restricted-globals
          history.replaceState(null, null, newUrl);
        });
    };
    registerVisit();
  }, []);
  return (
    <div className="backgroundIMG">
      <Header />
      <Features />
      <CompanyOverview />
      <Merchant />
      <StepByStep />
    </div>
  );
};

export default HomePage;
