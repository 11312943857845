import { Button, Divider, Typography } from "antd";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import classes from "./contact.module.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

function ContactUsForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const [t, i18n] = useTranslation();
  const contactData = {
    header: t("headerGreeting"),
    subtitle: t("subtitleGreeting"),
    formName: t("formName"),
    formEmail: t("formEmail"),
    formNumber: t("formNumber"),
    formMessage: t("formMessage"),
    formSend: t("formSend"),
  };

  const sendDataHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const ipResp = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResp.json();
    const ip = ipData.ip;

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", "");
    formData.append("company_name", "");
    formData.append("message_body", message);
    formData.append("ip", ip);

    try {
      await axios.post(
        `https://py.tws.teqneia.com/contacts/?tenant_id=2`,
        formData
      );
      toast.success("Message successfully sent.", { position: "top-center" });
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");
    } catch (error) {
      toast.error("Unable to send. Please try again.", {
        position: "top-center",
      });
    }
  };
  return (
    <div
      style={{
        borderLeft: { md: "solid 1px" },
        borderColor: { md: "lightgray" },
        padding: "0 0.5rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <span className={classes.mainnHeader}>{contactData.header}</span>
          <span className={classes.subbHeader}>{contactData.subtitle}</span>
        </div>
        {/* <Divider /> */}
        <div className={classes.contactDetails}>
          <span>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3327 5.94961V14.2829C18.3327 14.8355 18.1132 15.3654 17.7225 15.7561C17.3318 16.1468 16.8019 16.3663 16.2493 16.3663H3.74935C3.19681 16.3663 2.66691 16.1468 2.27621 15.7561C1.88551 15.3654 1.66602 14.8355 1.66602 14.2829V5.94961"
                  stroke="#008ECC"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3327 5.94961C18.3327 5.39707 18.1132 4.86717 17.7225 4.47647C17.3318 4.08577 16.8019 3.86627 16.2493 3.86627H3.74935C3.19681 3.86627 2.66691 4.08577 2.27621 4.47647C1.88551 4.86717 1.66602 5.39707 1.66602 5.94961L8.89518 10.4635C9.22629 10.6704 9.60889 10.7802 9.99935 10.7802C10.3898 10.7802 10.7724 10.6704 11.1035 10.4635L18.3327 5.94961Z"
                  stroke="#008ECC"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ color: "#929292" }}>E-Mart@Teqneia.com</span>
            </div>
          </span>
          <Divider type="vertical" />
          <span>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.8602 8.95621C16.8602 14.3351 11.351 17.6488 10.2061 18.28C10.1427 18.315 10.0714 18.3333 9.999 18.3333C9.92657 18.3333 9.85532 18.315 9.79189 18.28C8.64615 17.6488 3.13867 14.3351 3.13867 8.95621C3.13867 4.66824 5.71146 1.66666 9.99943 1.66666C14.2874 1.66666 16.8602 4.66824 16.8602 8.95621Z"
                  stroke="#008ECC"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.56836 8.52743C6.56836 9.43722 6.92977 10.3098 7.57309 10.9531C8.21642 11.5964 9.08895 11.9578 9.99874 11.9578C10.9085 11.9578 11.7811 11.5964 12.4244 10.9531C13.0677 10.3098 13.4291 9.43722 13.4291 8.52743C13.4291 7.61763 13.0677 6.7451 12.4244 6.10178C11.7811 5.45846 10.9085 5.09705 9.99874 5.09705C9.08895 5.09705 8.21642 5.45846 7.57309 6.10178C6.92977 6.7451 6.56836 7.61763 6.56836 8.52743V8.52743Z"
                  stroke="#008ECC"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ color: "#929292" }}>
                {t("Beverly hills")}, {t("Mixed-use area")}, {t("office 4")},
                {t("building 6")}
              </span>
            </div>
          </span>
          <Divider type="vertical" />
          <span>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.2509 17.3852L12.26 17.3915C13.0471 17.8927 13.9815 18.1103 14.909 18.0084C15.8365 17.9066 16.7014 17.4913 17.361 16.8314L17.9339 16.2584C18.0609 16.1315 18.1616 15.9809 18.2303 15.815C18.299 15.6492 18.3344 15.4715 18.3344 15.292C18.3344 15.1125 18.299 14.9347 18.2303 14.7689C18.1616 14.6031 18.0609 14.4524 17.9339 14.3255L15.5173 11.9108C15.3905 11.7838 15.2398 11.6831 15.074 11.6144C14.9081 11.5457 14.7304 11.5103 14.5509 11.5103C14.3714 11.5103 14.1937 11.5457 14.0278 11.6144C13.862 11.6831 13.7113 11.7838 13.5845 11.9108C13.3282 12.1669 12.9808 12.3108 12.6185 12.3108C12.2562 12.3108 11.9087 12.1669 11.6525 11.9108L7.78853 8.04592C7.53238 7.7897 7.38849 7.44223 7.38849 7.07993C7.38849 6.71763 7.53238 6.37017 7.78853 6.11394C7.91549 5.98706 8.01621 5.8364 8.08493 5.67057C8.15365 5.50474 8.18902 5.327 8.18902 5.1475C8.18902 4.968 8.15365 4.79026 8.08493 4.62443C8.01621 4.4586 7.91549 4.30794 7.78853 4.18105L5.37287 1.76631C5.11665 1.51017 4.76918 1.36627 4.40688 1.36627C4.04458 1.36627 3.69712 1.51017 3.44089 1.76631L2.86704 2.33926C2.20719 2.9989 1.79213 3.86391 1.69044 4.79137C1.58875 5.71884 1.80652 6.65324 2.30776 7.44018L2.31323 7.44929C4.96041 11.3659 8.33385 14.7387 12.2509 17.3852V17.3852Z"
                  stroke="#008ECC"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ color: "#929292" }}>
                {" "}
                {i18n.language === "ar" ? "1136515 101 20+" : "+20 101 1136515"}
              </span>
            </div>
          </span>
        </div>
        {/* <Divider /> */}
        <div style={{ marginY: "1rem" }}>
          <form className={classes.footerForm} onSubmit={sendDataHandler}>
            <div
              style={{
                marginY: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className={classes.ContactInputsWrapper}>
                <div className={classes.ContactInputs}>
                  <Typography Title level={5} style={{ marginBottom: "5px" }}>
                    {contactData.formName}
                  </Typography>
                  <input
                    type="text"
                    placeholder={t("formName")}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className={classes.InputField}
                  />
                </div>
                <div className={classes.ContactInputs}>
                  <Typography Title level={5} style={{ marginBottom: "5px" }}>
                    {contactData.formNumber}
                  </Typography>
                  <input
                    type="number"
                    placeholder={t("formNumber")}
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                    className={classes.InputField}
                  />
                </div>
              </div>
              <div className={classes.ContactInputs}>
                <Typography Title level={5} style={{ marginBottom: "5px" }}>
                  {contactData.formEmail}
                </Typography>
                <input
                  type="email"
                  placeholder={t("formEmail")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className={classes.InputField}
                />
              </div>
              <div className={classes.ContactInputs}>
                <Typography Title level={5} style={{ marginBottom: "5px" }}>
                  {contactData.formMessage}
                </Typography>
                <textarea
                  name=""
                  id=""
                  placeholder={t("formMessage")}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className={classes.InputField}
                />
              </div>
              <Button
                style={{ width: "45%" }}
                type="primary"
                size="large"
                htmlType="submit"
              >
                {contactData.formSend}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
