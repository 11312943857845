import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import app from "../../assets/app.svg";
import coding from "../../assets/codeing.svg";
import classes from "./merchant.module.css";
import { BsCheck2Circle, BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { CheckCircleOutlined } from "@ant-design/icons";

const Merchant = () => {
  const [t, i18n] = useTranslation();
  return (
    <section className={classes.merchant}>
      {/*{i18next.language === "en" && <div className={classes.divCircle}></div>} */}
      <Container className={classes.merchantContainer}>
        <Row lg={2}>
          <div>
            <div className={classes.merchantContent}>
              <h1 className={classes.merchantHeadtitle}>{t("any business")}</h1>
              <ul className={classes.merchantList}>
                <li>
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <CheckCircleOutlined className={classes.check} />
                  <p>{t("first type")}</p>
                </li>
                <li>
                  <CheckCircleOutlined className={classes.check} />
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <p>{t("sec type")}</p>
                </li>
                <li>
                  <CheckCircleOutlined className={classes.check} />
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <p>{t("third type")}</p>
                </li>
                <li>
                  <CheckCircleOutlined className={classes.check} />
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <p>{t("fourth")}</p>
                </li>

                <li>
                  <CheckCircleOutlined className={classes.check} />
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <p>{t("fifth")}</p>
                </li>
                <li>
                  <CheckCircleOutlined className={classes.check} />
                  {/* <BsCheck2Circle className={classes.check} /> */}
                  <p>{t("sixth")}</p>
                </li>
              </ul>
              {/* <div className={classes.buttons}>
                <button>Subscribe Now</button>
              </div> */}
            </div>
          </div>
          <div className={classes.imgcontainer}>
            <div className={classes.content}>
              <div className={classes.contentText}>
                <div className={classes.contentValue}>
                  <img src={app} className={classes.appImg} alt="app-img" />
                  <p>{t("Web app")}</p>
                </div>
                <div className={classes.contentValue}>
                  <img src={coding} className={classes.appImg} alt="app-img" />
                  <p>{t("mobile app")}</p>
                </div>
              </div>

              {/* <img src={merchantImg} alt="" /> */}
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Merchant;
