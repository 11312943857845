import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./help.module.css";
import HelpCenterIcon from "../../assets/icon/Help Center icon.png";
import { Collapse } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";
const { Panel } = Collapse;

const HelpCenter = () => {
  ReactGA.initialize("G-4W87Q53T7Y");
  // Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: "/helpcenter",
    title: "Veiwing Help Page",
  });

  const [helpCenterQA, setHelpCenterQA] = useState();
  const lang = localStorage.getItem("i18nextLng");

  const onChange = (key) => {};

  useEffect(() => {
    (async () => {
      const res = await axios.get(`https://py.e-mart.app/api/v1/contact/QA`);
      setHelpCenterQA(res?.data?.results);
    })();
  }, []);

  return (
    <div className={classes.helpContainer}>
      <div className={classes.helpContent}>
        <Container fluid>
          <Row>
            <Col lg={2}>
              <div className={classes.imgContainer}>
                <img src={HelpCenterIcon} alt="" />
              </div>
            </Col>
            <Col lg={9}>
              <div className={classes.expandContainer}>
                <Collapse defaultActiveKey={["1"]} onChange={onChange}>
                  {helpCenterQA?.map((QA, index) => (
                    <Panel
                      header={
                        lang === "en-US" ? QA?.question?.en : QA?.question?.ar
                      }
                      key={++index}
                    >
                      <p>
                        {lang === "en-US" ? QA?.answer?.en : QA?.answer?.ar}
                      </p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HelpCenter;
