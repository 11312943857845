import axios from "axios";
import { useEffect, useState } from "react";
import classes from "./customizePlan.module.css";
import { useTranslation } from "react-i18next";

function CustomizePlan(props) {
  const [t, i18n] = useTranslation();
  const countryInLocalStorage = localStorage.getItem("userCountryName");

  const [products, setProducts] = useState();
  const [project, setProject] = useState(1);
  const [storage, setStorage] = useState();
  const [accounts, setAccounts] = useState();
  const [flag, setFlag] = useState(false);
  const [register, setRegister] = useState(true);
  const [total, setTotal] = useState();
  const [currency, setCurrency] = useState();

  const [customizedPlanID, setCustomizedPlanId] = useState(0);
  const [customizedPlan, setCustomizedPlan] = useState();
  const [limitMessage, setLimitMessage] = useState("");

  useEffect(() => {
    if (countryInLocalStorage === "Egypt") {
      setCurrency("EGP");
    } else {
      setCurrency("USD");
    }
  }, [countryInLocalStorage]);

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const sendDataHandler = async (e) => {
    e.preventDefault();
    if (products < 15 || accounts < 1 || storage < 3) {
      if (products < 15) {
        setLimitMessage("your products should be more than 15");
      } else if (accounts < 1) {
        setLimitMessage("your accounts should be more than 1");
      } else {
        setLimitMessage("your storage should be more than 3");
      }
    } else {
      const data = {
        products,
        storage,
        accounts,
        country: countryInLocalStorage,
      };
      try {
        const response = await axios.post(
          `https://py.e-mart.app/api/v1/plan/custom/check`,
          data
        );
        if (response.status === 200 || response.status === 201) {
          setTotal((pre) => response?.data?.price);
          setCurrency((pre) => response?.data?.currency);
          setFlag(true);
        }
        setLimitMessage("");
      } catch (error) {}
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    const data = {
      products,
      storage,
      accounts,
      country: countryInLocalStorage,
    };
    try {
      const response = await axios.post(
        `https://py.e-mart.app/api/v1/plan/custom/create`,
        data
      );
      if (response.status === 201 || response.status === 200) {
        setCustomizedPlanId((pre) => response?.data?.id);
        setCustomizedPlan((pre) => response?.data);
        // setRegister(true);

        const dataAsString = JSON.stringify(response?.data);
        const encodedData = encodeURIComponent(dataAsString);
        const url = `https://payment.teqneia.com/project/?project=${project}&data=${encodedData}&register=${register}`;
        window.open(url);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="my-5 text-center" ref={props.refProp}>
        <div className={classes.plan_head}>
          <h6 className="subColor">
            {currency} {flag ? addCommas(Math.round(total)) : "00.00"}
          </h6>
          <h2 className={classes.plan_name}>{t("custom")}</h2>
          <button className={classes.plan_btn} onClick={submitData}>
            Get started
          </button>
        </div>
        {limitMessage && <h5 className={classes.error}>{limitMessage}</h5>}
        <form className="mt-4">
          <div className="row">
            <div className="col-lg-4 col-sm-12 text-start">
              <div className="position-relative">
                <input
                  type="number"
                  required="required"
                  value={products}
                  className={classes.modalInput}
                  min={15}
                  onChange={(e) => setProducts(+e.target.value)}
                  placeholder={t("prodNum")}
                />
                <label className={classes.customLabel}>{t("products")}</label>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 text-start">
              <div className="position-relative">
                <input
                  type="number"
                  required="required"
                  value={storage}
                  className={classes.modalInput}
                  min={3}
                  onChange={(e) => setStorage(+e.target.value)}
                  placeholder={t("enterSize")}
                />
                <label className={classes.customLabel}>{t("size")}</label>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 text-start">
              <div className="position-relative">
                <input
                  type="number"
                  required="required"
                  value={accounts}
                  className={classes.modalInput}
                  min={1}
                  onChange={(e) => setAccounts(+e.target.value)}
                  placeholder={t("enterAccounts")}
                />
                <label className={classes.customLabel}>{t("accounts")}</label>
              </div>
            </div>
          </div>
        </form>
        <div className="text-center mt-3">
          <button
            onClick={sendDataHandler}
            className={classes.customBtn}
            style={{ bottom: "auto" }}
          >
            {t("calc")}
          </button>
        </div>
      </div>
    </>
  );
}

export default CustomizePlan;
