import React from "react";
import classes from "./contact.module.css";
import LetsCollaborate from "./LetsCollaborate";
import ContactUsForm from "./ContactUsForm";

const ContactUs = () => {
  return (
    <div>
      <LetsCollaborate />
      <div className={classes.ContactUsForm}>
        <ContactUsForm />
      </div>
    </div>
  );
};

export default ContactUs;
