import { Col, Container, Row } from "react-bootstrap";
import classes from "./stepByStep.module.css";
import secClasses from "./header.module.css";
import line from "../../assets/line.svg";
import RecentActivity from "../../assets/RecentActivities.svg";
import Webiste from "../../assets/website-mockup.svg";
import laptop from "../../assets/laptop.svg";
import { useTranslation } from "react-i18next";

const StepByStep = () => {
  const [t] = useTranslation();
  return (
    <Container fluid className={classes.content}>
      <h3 className={classes.title}>{t("guide")}</h3>
      <img src={line} className={classes.lineImg} alt="line img" />
      <Row className={classes.desktopView}>
        <Col className="col-md-5  col-lg-5">
          <p style={{ marginRight: "2rem" }} className={classes.number}>
            1
          </p>
          <div className={classes.subscribe}>
            <h2 className={classes.contentTitle}>
              <span className={classes.text_value}>{t("first step1")}</span>
              {t("first step2")}
            </h2>
            <p className={classes.contentText}>{t("first step info")}</p>
          </div>
        </Col>
        <Col className="col-md-7 col-lg-7">
          <img
            src={RecentActivity}
            alt="recent activity img"
            className={classes.mobileImg}
          />
        </Col>
        <Col className="col-md-5  col-lg-5">
          <img src={Webiste} alt="website img" className={classes.websiteImg} />
        </Col>
        <Col className="col-md-7 col-lg-7">
          <div className={classes.customize}>
            <p className={classes.number}>2</p>
            <div>
              <h2 className={classes.contentTitle}>
                <span className={classes.text_value}>{t("sec step1")}</span>
                {t("sec step2")}
              </h2>
              <p className={classes.contentText}>{t("sec step info")}</p>
            </div>
          </div>
        </Col>
        <Col className="col-md-5  col-lg-5">
          <div className={classes.thirdStep}>
            <p className={classes.finalNumber}>3</p>
            <div className={classes.subscribe}>
              <h2 className={classes.contentTitle}>
                <span className={classes.text_value}>{t("thrd step1")}</span>
                {t("thrd step2")}{" "}
              </h2>
              <p className={classes.contentText}>{t("thrd step info")}</p>
            </div>
            <a href="/plans">
              <button
                style={{
                  marginRight: "3rem",
                  marginLeft: "3rem",
                  marginBottom: "2rem",
                }}
                className={secClasses.storeBtn}
              >
                {t("button after steps")}
              </button>
            </a>
          </div>
        </Col>
        <Col className="col-md-7 col-lg-7">
          <img
            src={laptop}
            className={classes.laptopImg}
            alt="img of a laptop"
          />
        </Col>
      </Row>

      {/*MOBILE VIEW*/}
      <Container>
        <Col className={classes.mobileView}>
          <div className={classes.stepContainer}>
            <p className={classes.number}>1</p>
            <div className={classes.subscribe}>
              <h2 className={classes.contentTitle}>
                <span className={classes.text_value}>{t("first step1")}</span>
                {t("first step2")}
              </h2>
              <p className={classes.contentText}>{t("first step info")}</p>
            </div>
          </div>

          <Col className="">
            <div className={classes.stepContainer}>
              <p className={classes.number}>2</p>
              <div>
                <h2 className={classes.contentTitle}>
                  <span className={classes.text_value}>{t("sec step1")}</span>
                  {t("sec step2")}
                </h2>
                <p className={classes.contentText}>{t("sec step info")}</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className={classes.thirdStep}>
              <p className={classes.finalNumber}>3</p>
              <div className={classes.subscribe}>
                <h2 className={classes.contentTitle}>
                  <span className={classes.text_value}>{t("thrd step1")}</span>
                  {t("thrd step2")}{" "}
                </h2>
                <p className={classes.contentText}>{t("thrd step info")}</p>
              </div>
            </div>
            <a href="/plans">
              <button
                style={{
                  marginRight: "3rem",
                  marginLeft: "3rem",
                  marginBottom: "2rem",
                }}
                className={secClasses.storeBtn}
              >
                {t("button after steps")}
              </button>
            </a>
          </Col>
        </Col>
      </Container>
      {/*MOBILE VIEW -- END --*/}
    </Container>
  );
};

export default StepByStep;
