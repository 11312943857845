import React from 'react'
import ContactUs from '../components/contactUs/ContactUs'

function ContactUsPage() {
  return (
    <div>
      <ContactUs />
    </div>
  )
}

export default ContactUsPage
