import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./header.module.css";
import headerIMG from "../../assets/home.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation();
  return (
    <header className={classes.header}>
      <Container>
        <Row className={`${"flex-sm-column-reverse"} ${"flex-lg-row"}`}>
          <Col lg={6} className={`${classes.flexItem} col-md-12`}>
            <div className={classes.headerContent}>
              <p>
                {t("headerOne")}{" "}
                <span className={classes.text_value}> {t("app-title")} </span>{" "}
                {t("headerTitle")}.{/* {t("headerOne")}{" "} */}
                {/* <span className={classes.minutes}>{t("headerSpan")}</span>. */}
              </p>
              <span className={classes.paragraph}>{t("headerTwo")}</span>
              <div className={classes.contentBtn}>
                <a href="/plans">
                  <button className={classes.storeBtn}>
                    {t("get your store")}
                  </button>
                </a>
                <a href="/contactUS" target="_self">
                  <button className={classes.massageBtn}>
                    {t("send a message")}
                  </button>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6} className="col-md-12">
            <div className={classes.headerImage}>
              <img src={headerIMG} alt="" />
            </div>
          </Col>
          {/* <div className={classes.buttons}>
            <button>Subscribe Now</button>
          </div> */}
        </Row>
      </Container>
    </header>
  );
};

export default Header;
