import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ContactUsPage from "./pages/ContactUsPage";
import HelpCenterPage from "./pages/HelpCenterPage";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import HeaderNew from "./components/layout/Header_new/HeaderNew";
import Plans from "./pages/Plans";
import Subscribe from "./components/plan/Subscribe";
import FooterNew from "./components/layout/footer/FooterNew";
import FAQs from "./pages/FAQs";

const App = () => {
  // const location = useLocation();
  const VisitorAPI = require("visitorapi");

  const [visitorData, setVisitorData] = useState({}); // store the whole json

  const [ipAddress, setIpAddress] = useState("");

  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [cityLatLong, setCityLatLong] = useState(""); // city center latitude and longitude

  const [currencies, setCurrencies] = useState([]); // currencies data is an array
  const [languages, setLanguages] = useState([]); // languages data is an array

  const [browser, setBrowser] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");

  const [deviceBrand, setDeviceBrand] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceFamily, setDeviceFamily] = useState("");

  const [os, setOs] = useState("");
  const [osVersion, setOsVersion] = useState("");

  useEffect(() => {
    VisitorAPI("FjxIALRdAmnRUwlC1moo", (data) => {
      setVisitorData(data);
      setIpAddress(data.ipAddress);
      setCountryCode(data.countryCode);
      setCountryName(data.countryName);
      setState(data.region);
      setCity(data.city);
      setCityLatLong(data.cityLatLong);
      setCurrencies(data.currencies);
      setLanguages(data.languages);
      setBrowser(data.browser);
      setBrowserVersion(data.browserVersion);
      setDeviceBrand(data.deviceBrand);
      setDeviceModel(data.deviceModel);
      setDeviceFamily(data.deviceFamily);
      setOs(data.os);
      setOsVersion(data.osVersion);
      if (!JSON.parse(localStorage.getItem("currentLang"))) {
        if (data.languages[0] === "ara") {
          localStorage.setItem(
            "currentLang",
            JSON.stringify({
              lang: "ar",
            })
          );
        } else {
          localStorage.setItem(
            "currentLang",
            JSON.stringify({
              lang: "en",
            })
          );
        }
      }
    });
  }, []);

  let currentLang = JSON.parse(localStorage.getItem("currentLang"));

  return (
    <div>
      <Toaster />
      <Router>
        <HeaderNew currentLang={currentLang} />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/helpcenter">
            <HelpCenterPage />
          </Route>
          <Route exact path="/subscribe">
            <Subscribe />
          </Route>
          <Route exact path="/plans">
            <Plans />
          </Route>
          <Route exact path="/contactUS">
            <ContactUsPage />
          </Route>
          <Route exact path="/faqs">
            <FAQs />
          </Route>
          <Route exact path="*">
            <ErrorPage />
          </Route>
        </Switch>
        <FooterNew />
      </Router>
    </div>
  );
};

export default App;
