import React, { useEffect, useState } from "react";
import classes from "./plan.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import darkcheckIcon from "../../assets/icon/Filled Icons/Check Circle Icon.svg";

const Plan = () => {
  const countryInLocalStorage = localStorage.getItem("userCountryName");
  const [t, i18n] = useTranslation();
  const [plans, setPlans] = useState([]);
  const [project, setProject] = useState(1);
  const [register, setRegister] = useState(false);
  const [removeSomeFeatures, setRemoveSomeFeatures] = useState([
    "Storage space",
    "Number of products",
    "Accessible design",
    "Integration with payment gateways",
    "staff_account",
  ]);
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const toPlanPage = (data) => {
    const dataAsString = JSON.stringify(data);
    const encodedData = encodeURIComponent(dataAsString);

    setRegister(true);
    const url = `https://payment.teqneia.com/project/?project=${project}&data=${encodedData}&register=${register}`;
    window.open(url);
  };

  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `https://py.e-mart.app/api/v1/plan/?main=True&order_by=value_annual`,
        {
          params: { country: countryInLocalStorage },
        }
      );
      setPlans(res?.data?.results);
      setProject(1);
    })();
  }, [countryInLocalStorage]);

  const [features, setFeatures] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`https://py.e-mart.app/api/v1/plan/feature`);
      setFeatures(res?.data?.results);
    })();
  }, [countryInLocalStorage]);

  const featureslist =
    features &&
    features.map((feature, rowIndex) => {
      if (!removeSomeFeatures.includes(feature.name)) {
        return (
          <tr key={feature.id}>
            <td className={classes.feature_check}>{feature.name}</td>
            {plans &&
              plans.map((plan) => (
                <td key={plan.id}>
                  {plan.feature.map((planFeature, index) =>
                    rowIndex === index ? (
                      <img
                        key={+planFeature.id}
                        src={darkcheckIcon}
                        width="24"
                        alt="Check Icon"
                      />
                    ) : null
                  )}
                </td>
              ))}
          </tr>
        );
      } else {
        return null; // Exclude features that should be removed
      }
    });
  return (
    <div style={{ overflowX: "auto" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <th>
            <h1 className={classes.featureTitle}>Features</h1>
          </th>
          {plans.map((plan) => (
            <th key={+plan.id}>
              <div
                style={{
                  display: plan.old_price > 0 ? "flex" : "block",
                  flexDirection: plan.old_price > 0 ? "column" : "row",
                }}
              >
                {plan.old_price > 0 ? (
                  <h6
                    className="subColor"
                    style={{
                      display: plan.old_price > 0 ? "block" : "none",
                      textDecorationLine: "line-through",
                      color: "red",
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                  >
                    {plan.currency} {addCommas(plan.old_price)}
                  </h6>
                ) : (
                  <></>
                )}
                <h6 className="subColor">
                  {plan.currency} <span>{addCommas(plan.value_annual)}</span>
                </h6>
              </div>
              <h2 className={classes.plan_name}>{plan.name}</h2>

              <button
                className={classes.plan_btn}
                onClick={() => toPlanPage(plan)}
              >
                Get started
              </button>
            </th>
          ))}
        </thead>
        <tbody>
          <tr>
            <td> Number of products</td>

            {plans.map((plan, index) => (
              <>
                {plan.feature && (
                  <td className={classes.feature_check} key={index}>
                    <b>{plan.Products}</b>
                  </td>
                )}
              </>
            ))}
          </tr>
          <tr>
            <td>Storage space</td>

            {plans.map((plan, index) => (
              <>
                {plan.feature && (
                  <td className={classes.feature_check} key={index}>
                    <b>{plan.storage} GB</b>
                  </td>
                )}
              </>
            ))}
          </tr>
          <tr>
            <td>Staff account</td>

            {plans.map((plan, index) => (
              <>
                {plan.feature && (
                  <td className={classes.feature_check} key={index}>
                    <b>{plan.staff_account}</b>
                  </td>
                )}
              </>
            ))}
          </tr>
          {featureslist}
        </tbody>
      </table>
    </div>
  );
};

export default Plan;
