import React from "react";
import { useTranslation } from "react-i18next";

function Main() {
    const [t, i18n] = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ marginTop: "1rem" }}>
        <span style={{ color: "gray", display: "block", fontSize: "3rem" }}>
          <span>4 0 4</span>
        </span>
        <span>{t('error')}</span>
      </div>
      <button
        style={{
          margin: "1.5rem",
          width: "125px",
          borderRadius: "6px",
          height: "41px",
          backgroundColor: "#035f86",
          color: "#ffffff",
          border: "none",
        }}
        onClick={() => {
          window.location.pathname = "/";
        }}
      >
        {t('home')}
      </button>
    </div>
  );
}

export default Main;
