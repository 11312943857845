import React from 'react'
import ErrorPageNotFound from '../components/ErrorPageNotFound'

function ErrorPage() {
  return (
    <div>
      <ErrorPageNotFound/>
    </div>
  )
}

export default ErrorPage
