import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./features.module.css";
import icon2 from "../../assets/cart.svg";
import icon3 from "../../assets/ShieldLock.svg";
import icon4 from "../../assets/clock.svg";
import icon1 from "../../assets/display.svg";
// import icon4 from "../../assets/icon/Go Worldwide icon.png";
// import icon5 from "../../assets/icon/Secured Platform icon.png";
// import icon6 from "../../assets/icon/Simple User Interface icon.png";

import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Features = () => {
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 66;
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);

  const data = [
    {
      icon: icon2,
      title: t("featuresH3Five"),
      content: t("featuresPFive"),
    },
    {
      icon: icon3,
      title: t("featuresH3Two"),
      content: t("featurespTwo"),
    },
    {
      icon: icon4,
      title: t("featuresH3one"),
      content: t("featurespOne"),
    },
    {
      icon: icon1,
      title: t("featuresH3Three"),
      content: t("featurespThree"),
    },
  ];

  return (
    <div className={classes.featuresSection}>
      <hr />
      <Container>
        <Row>
          {data.map((item) => (
            <Col key={item.title} className="col-md-3">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <div>
                  <img src={item.icon} alt="" />
                </div>
                <div className={`${classes.featuresText}`}>
                  <h3>{item.title}</h3>
                  {/* <h3>{t("featuresH3one")}</h3> */}
                  <p>{item.content}</p>
                  {/* <p>
              {t("featurespOne")}
              <p>{t("featurespOne1")}</p>
              {t("featurespOne2")}
            </p> */}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <hr />
    </div>
    // <Element name="Features">
    //   <div className={`${classes.features}`}>
    //     <Container fluid>
    //       <h3>{t("featuresH3")}</h3>
    //       {i18next.language === "en" && <p>{t("featuresP")}</p>}
    //       <Row>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations1
    //             }`}
    //           >
    //             <img src={icon2} alt="" />
    //             <h3>{t("featuresH3one")}</h3>
    //             <p>
    //               {t("featurespOne")}
    //               <p>{t("featurespOne1")}</p>
    //               {t("featurespOne2")}
    //             </p>
    //           </div>
    //         </Col>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations2
    //             }`}
    //           >
    //             <img src={icon5} alt="" />
    //             <h3>{t("featuresH3Two")}</h3>
    //             <p>{t("featurespTwo")}</p>
    //           </div>
    //         </Col>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations3
    //             }`}
    //           >
    //             <img src={icon6} alt="" />
    //             <h3>{t("featuresH3Three")}</h3>
    //             <p>{t("featurespThree")} </p>
    //           </div>
    //         </Col>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations4
    //             }`}
    //           >
    //             <img src={icon3} alt="" />
    //             <h3>{t("featuresH3Four")}</h3>
    //             <p>{t("featurespFour")}</p>
    //           </div>
    //         </Col>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations5
    //             }`}
    //           >
    //             <img src={icon1} alt="" />
    //             <h3>{t("featuresH3Five")}</h3>
    //             <p>{t("featuresPFive")}</p>
    //           </div>
    //         </Col>
    //         <Col lg={4} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations6
    //             }`}
    //           >
    //             <img src={icon4} alt="" />
    //             <h3>{t("featuresH3Six")}</h3>
    //             <p>{t("featuresPSex")} </p>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </Element>
  );
};

export default Features;
