import React from "react";
import EMartsLogo from "../../../assets/logo/mainLogo.svg";
import EMartLogo from "../../../assets/logo/EMartLogo.jpeg";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
import "./HeaderNew.css";
const HeaderNew = ({ currentLang }) => {
  const languages = [
    {
      code: "en",
      name: "English",
      Country_code: "gb",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      Country_code: "sa",
      dir: "rtl",
    },
  ];
  const currentLangsCode =
    JSON.parse(localStorage.getItem("currentLang")) || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode.lang);
  const [t, i18n] = useTranslation();

  const [navbar, setNavbar] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => setNavbar(window.scrollY));
  }, [navbar]);

  const scrollToNextSection = () => {
    scroller.scrollTo("Plans", { smooth: true, duration: 100 });
  };

  const scrollToNextSection1 = () => {
    // scroller.scrollTo("Features", { smooth: true, duration: 100 });
  };

  useEffect(() => {
    document.body.dir = currentLangs?.dir || "ltr";
    document.title = t("app-title");
  }, [currentLangs, t]);

  useEffect(() => {
    if (currentLang?.lang === "ar") {
      i18next.changeLanguage("ar");
    } else {
      i18next.changeLanguage("en");
    }
  }, [currentLang]);

  const [menuOpen, setMenuOpen] = useState(false);
  const path = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [path]);

  return (
    <div>
      <nav>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to="/" className="title">
            <img src={EMartLogo} alt="logo" />
          </Link>
          <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className={menuOpen ? "open" : ""}>
          <li>
            <NavLink to="/" exact>
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/plans">{t("plans")}</NavLink>
          </li>
          <li>
            <NavLink to="/faqs">{t("Frequently Asked Questions")}</NavLink>
          </li>
          {i18next.language === "ar" && (
            <li>
              <a
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  i18next.changeLanguage("en");
                  localStorage.setItem(
                    "currentLang",
                    JSON.stringify({
                      lang: "en",
                    })
                  );
                  setMenuOpen(false);
                }}
              >
                English
              </a>
            </li>
          )}
          {i18next.language === "en" && (
            <li>
              <a
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  i18next.changeLanguage("ar");
                  localStorage.setItem(
                    "currentLang",
                    JSON.stringify({
                      lang: "ar",
                    })
                  );
                  setMenuOpen(false);
                }}
              >
                عربي
              </a>
            </li>
          )}
          <li id="contactBtn">
            <NavLink to="/contactUS">{t("contactUs")}</NavLink>
          </li>
          <li id="contact">
            <NavLink to="/contactUS">{t("contactUs")}</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderNew;
