import { Col, Container, Row } from "react-bootstrap";
import classes from "./companyOverview.module.css";
import mobile from "../../assets/mobile.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const CompanyOverview = () => {
  const [t, i18n] = useTranslation();
  return (
    <Container>
      <h3 className={classes.title}>{t("company overview")}</h3>
      <div className={classes.content}>
        <div className="col-md-8 col-lg-6">
          <img src={mobile} alt="" className={classes.img} />
        </div>
        <div className="">
          <h2 className={classes.contentTitle}>
            {t("the overview1")}{" "}
            <span className={classes.text_value}> {t("the overview2")} </span>
            {t("the overview3")}{" "}
            <span className={classes.text_value}>{t("the overview4")} </span>{" "}
            {t("the overview5")}{" "}
          </h2>
          <p className={classes.contentText}>{t("overview cont")}</p>
        </div>
      </div>
    </Container>
  );
};

export default CompanyOverview;
