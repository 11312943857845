import { Divider } from "antd";
import React from "react";
import mobileImage from "../../assets/17. Recent Activities 1.png";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Main() {
  const [t] = useTranslation();

  const data = [
    {
      question: t("helpH1"),
      answer: t("text1"),
    },
    {
      question: t("helpH2"),
      answer: t("text2"),
    },
    {
      question: t("helpH3"),
      answer: t("text3"),
    },
    {
      question: t("helpH4"),
      answer: t("text4"),
    },
    {
      question: t("helpH5"),
      answer: t("text5"),
    },
    {
      question: t("helpH6"),
      answer: t("text6"),
    },
  ];
  return (
    <Container>
      <div
        style={{
          marginTop: "7%",
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          gap: "25px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "5%",
            alignItems: "start",
            maxWidth: "max-content",
          }}
        >
          <div
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "2.8rem",
              fontStyle: "normal",
              fontWeight: " 600",
              lineHeight: "170%",
            }}
          >
            {t("Frequently Asked Questions")}
          </div>
          <div
            style={{
              color: "#777",
              fontFamily: " Poppins",
              fontSize: " 20px",
              fontStyle: " normal",
              fontWeight: "400",
              lineHeight: "189.5%",
            }}
          >
            Frequently Asked Questions
          </div>
          <Divider />
          <div style={{ margin: "1rem" }}>
            <img
              src={mobileImage}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "58px",
          }}
        >
          {data.map((q, index) => (
            <div
              style={{
                marginBottom: "1rem",
                maxWidth: "fit-content",
                padding: "0.5rem",
              }}
            >
              <div
                style={{
                  color: "#888",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "27px",
                }}
              >
                {q.question}
              </div>
              <div
                style={{
                  color: "#777",
                  fontFamily: "Inter",
                  fontSize: " 20px",
                  fontStyle: " normal",
                  fontWeight: "400",
                  lineHeight: "27px",
                }}
              >
                {q.answer}
              </div>
            </div>
          ))}
          <button
            style={{
              margin: "1.5rem",
              width: "125px",
              borderRadius: "6px",
              height: "41px",
              backgroundColor: "#035f86",
              color: "#ffffff",
              border: "none",
            }}
            onClick={() => {
              window.location.pathname = "/contactUS";
            }}
          >
            {t("contactUs")}
          </button>
        </div>
      </div>
    </Container>
  );
}

export default Main;
