// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".help_helpContainer__HxL-4 {\n  padding: 150px 30px 40px;\n}\n\n.help_helpContent__m0Avk {\n  background: #f1f0f7;\n  border-radius: 20px;\n  padding: 20px;\n}\n\n.help_imgContainer__hwB1N img {\n  height: 150px;\n  /* width: 100%; */\n}\n\n.help_expandContainer__5On9E {\n  border-left: 6px solid #7965ab;\n}\n", "",{"version":3,"sources":["webpack://./src/components/helpCenter/help.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".helpContainer {\n  padding: 150px 30px 40px;\n}\n\n.helpContent {\n  background: #f1f0f7;\n  border-radius: 20px;\n  padding: 20px;\n}\n\n.imgContainer img {\n  height: 150px;\n  /* width: 100%; */\n}\n\n.expandContainer {\n  border-left: 6px solid #7965ab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpContainer": "help_helpContainer__HxL-4",
	"helpContent": "help_helpContent__m0Avk",
	"imgContainer": "help_imgContainer__hwB1N",
	"expandContainer": "help_expandContainer__5On9E"
};
export default ___CSS_LOADER_EXPORT___;
