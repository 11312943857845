import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./plans.module.css";
import Plan from "../components/plan/Plan";
import CustomizePlan from "../components/plan/CustomizePlan";
import ReactGA from "react-ga4";

const Plans = () => {
  const VisitorAPI = require("visitorapi");
  ReactGA.initialize("G-4W87Q53T7Y");
  // Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: "/plans",
    title: "Viewing plans page",
  });

  const [t, i18n] = useTranslation();
  const myRef = useRef(null);

  const [countryName, setCountryName] = useState("");

  useEffect(() => {
    VisitorAPI("FjxIALRdAmnRUwlC1moo", (data) => {
      localStorage.setItem(
        "userCountryName",
        data.countryName ? data.countryName : "Egypt"
      );
    });
    localStorage.setItem(
      "userCountryName",
      localStorage.getItem("userCountryName")
        ? localStorage.getItem("userCountryName")
        : "Egypt"
    );
  }, []);

  useEffect(() => {
    // function trimUrl(url) {
    //   const regex = /^(https?:\/\/)(www\.)/;
    //   return url.replace(regex, "$1");
    // }
    const registerVisit = async () => {
      await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          const ipAddress = data.ip;
          const query = window.location.search;
          const params = new URLSearchParams(query);
          const source = params.get("s");

          const formData = new FormData();
          formData.append("source_website", document.referrer);
          formData.append("page", window.location.href);
          formData.append("ip", ipAddress);
          if (!source === null) {
            formData.append("reference", source);
          }
          fetch("https://py.dws.teqneia.com/create-report/?tenant_id=1", {
            method: "POST",
            body: formData,
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Unable to register visit.");
            }
            return response.json();
          });

          params.delete("source");
          const newUrl = window.location.pathname;
          // eslint-disable-next-line no-restricted-globals
          history.replaceState(null, null, newUrl);
        });
    };
    registerVisit();
  }, []);

  const handleClick = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const local = localStorage.getItem("userCountryName");

  return (
    <>
      <main className={classes.plans}>
        <div className="container">
          <h2 className={classes.title}>{t("affordPlan")}</h2>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h5 className={classes.subtitle}>{t("choosePlan")}</h5>
            <div style={{ margin: "1.5rem" }}>
              <span className={classes.subtitlebtn} onClick={handleClick}>
                {t("customizeBtn")}
              </span>
            </div>
          </div>
          <div
            style={{ direction: "ltr" }}
            className={`row mt-5 position-relative ${classes.row}`}
          >
            <Plan />
          </div>
          <CustomizePlan refProp={myRef} />
        </div>
      </main>
    </>
  );
};

export default Plans;
