import React from "react";
import classes from "./footer.module.css";
import fbIcon from "../../../assets/icon/socialMediaIcon/Facebook.svg";
import igIcon from "../../../assets/icon/socialMediaIcon/Instagram.svg";
import inIcon from "../../../assets/icon/socialMediaIcon/LinkedIn.svg";
import twIcon from "../../../assets/icon/socialMediaIcon/Twitter.svg";
import ytIcon from "../../../assets/icon/socialMediaIcon/YouTube.svg";

const FooterNew = () => {
  return (
    <footer>
      <div
        className="whatsapp"
        style={{
          position: "fixed",
          bottom: "25px",
          right: "18px",
          zIndex: "999",
        }}
      >
        <a
          href="https://wa.me/+201011136515"
          rel="noreferrer"
          target="_blank"
          className="whatsapp_icon"
        >
          <svg
            id="Whatsapp"
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
          >
            <path
              id="Fill-232"
              d="M39.826,37.522a4.081,4.081,0,0,1-2.676,1.884,5.454,5.454,0,0,1-2.5-.156,23.092,23.092,0,0,1-2.258-.836C28.42,36.7,25.822,32.7,25.624,32.432a7.7,7.7,0,0,1-1.618-4.1,4.441,4.441,0,0,1,1.388-3.306,1.456,1.456,0,0,1,1.056-.5c.264,0,.528,0,.76.014s.57-.092.892.68c.33.794,1.122,2.744,1.222,2.942a.733.733,0,0,1,.032.694,2.728,2.728,0,0,1-.4.662c-.2.23-.416.516-.594.694-.2.2-.406.412-.174.808a11.963,11.963,0,0,0,2.2,2.744,10.887,10.887,0,0,0,3.188,1.968c.4.2.628.164.858-.1s.992-1.156,1.256-1.554.528-.33.892-.2,2.312,1.09,2.708,1.29.66.3.76.462a3.327,3.327,0,0,1-.232,1.884M32.072,18.678a13.4,13.4,0,0,0-6.612,1.779A13.18,13.18,0,0,0,20.9,38.87l.314.5L19.88,44.232l4.99-1.308.48.284a13.145,13.145,0,0,0,6.71,1.838h.006a13.184,13.184,0,0,0,.006-26.368"
              fill="#b2d235"
              fill-rule="evenodd"
            />
            <g id="Fill-324">
              <path
                id="Fill-233"
                d="M32.066,47.724H32.06a15.827,15.827,0,0,1-7.58-1.93L16.07,48l2.252-8.218a15.862,15.862,0,1,1,13.744,7.942M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0"
                fill="#b2d235"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>
      </div>
      <div className={classes.footerContent}>
        <div className={classes.contacts}>
          <ul
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "2rem",
            }}
          >
            <li>
              <a
              //  style={{cursor:"pointer"}}
              >
                All Rights Reserved
              </a>
            </li>
            <li>
              <a
              //  style={{cursor:"pointer"}}
              >
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
              //  style={{cursor:"pointer"}}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p className={classes.companyLink}>
            Developed by{" "}
            <strong>
              <a href="https://teqneia.com/" target="_blank" rel="noreferrer">
                TEQNEIA
              </a>
            </strong>
          </p>
        </div>
      </div>
      <div>
        <div>
          <div>
            <ul className={classes.socialList}>
              <li>
                <a
                  href="https://www.facebook.com/teqneia.emarts"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbIcon} alt="facebook icon" />
                </a>
              </li>
              {/* <li>
            <img src={twIcon} />
          </li> */}
              <li>
                <a
                  href="https://www.instagram.com/p/C0y8ABBISQM/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igIcon} alt="instagram icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/teqneia/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={inIcon} alt="linkedin icon" />
                </a>
              </li>
              {/* <li>
            <img src={ytIcon} />
          </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterNew;
